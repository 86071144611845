import { Box, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import StatArray from "./Components/StatArray"


function App() {





	const darkTheme = createTheme({
		palette:{
			mode:"dark",
			primary: {
				main: '#6170c5',
			},
			secondary: {
				main: '#f50057',
			},
			background: {
				default:"#303030",
				paper:"#424242"
			}
		},
	})








	return (
		<ThemeProvider theme={darkTheme}>
			<CssBaseline />
			<Box sx={{
				width:"100vw",
				height:"100vh",
				overflowX:"hidden",
				overflowY:"scroll",
				display:"flex",
				flexDirection:"column"
			}}>
				<Box sx={{
					width:1,
					flexGrow:1,
				}}>
					<StatArray/>
				</Box>
			</Box>
		</ThemeProvider>
	);
}

export default App;
