import {Box} from "@mui/material"
import {DataGrid} from "@mui/x-data-grid"
import {useState, useEffect} from "react"
import ReactStars from "react-rating-stars-component";
import { IgrLinearGaugeModule } from 'igniteui-react-gauges';
import { IgrLinearGraphRange, IgrLinearGauge } from 'igniteui-react-gauges';

IgrLinearGaugeModule.register();


const RStar = (rate) => {
	return (
		<ReactStars
			count={5}
			value={rate}
			edit={false}
			isHalf={true}
			size={24}
			activeColor="#ffd700"
		/>
	)
}

const lerp = (x, y, a) => x * (1 - a) + y * a;
const clamp = (a, min = 0, max = 1) => Math.min(max, Math.max(min, a));
const invlerp = (x, y, a) => clamp((a - x) / (y - x));
const range = (x1, y1, x2, y2, a) => lerp(x2, y2, invlerp(x1, y1, a));

const prettyNumber = (num) => {
	return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}



const collums = [
	{field:"id", headerName:"ID", width: 150, type:"string"},
	{field:"name", headerName:"Nom", width: 150, type:"string"},
	{field:"speedRate", headerName:"Taux de vitesse", width: 150, type:"number"
		,renderCell: (params) => {
			return RStar(params.value);
		},},
	{field:"fDriveBiasFront", headerName:"Biais de traction", width: 150, type:"number",
		renderCell: (params) => {
			return (
				<IgrLinearGauge width="200px"
					height="30px"
					minimumValue = {0}
					maximumValue = {2}
					interval={1}
					value = {params.value}
					needleBrush="green"
					needleOutline="#000000"
					backingBrush="transparent"
					backingOutline="transparent"
					minorTickCount={3}
					minorTickBrush="black"
					fontBrush="white"
				>
					<IgrLinearGraphRange key="front" startValue={0}
											endValue={1}
											brush="white"/>
					<IgrLinearGraphRange key="rear" startValue={1}
											endValue={2}
											brush="white"/>
				</IgrLinearGauge>
			)
		},},
	{field:"brakeRate", headerName:"Taux de freinage", width: 150, type:"number"
		,renderCell: (params) => {
			return RStar(params.value);
		},},
	{field:"fBrakeBiasFront", headerName:"Biais de freinage", width: 150, type:"number",
		renderCell: (params) => {
			return (
				<IgrLinearGauge width="200px"
					height="30px"
					minimumValue = {0}
					maximumValue = {1}
					interval={0.5}
					value = {params.value}
					needleBrush="green"
					needleOutline="#000000"
					backingBrush="transparent"
					backingOutline="transparent"
					minorTickCount={3}
					minorTickBrush="black"
					fontBrush="white"
				>
					<IgrLinearGraphRange key="front" startValue={0}
											endValue={1}
											brush="white"/>
					<IgrLinearGraphRange key="rear" startValue={0.5}
											endValue={1}
											brush="white"/>
				</IgrLinearGauge>
			)
		},},
	{field:"roadRateMax", headerName:"Tenu de route", width: 150, type:"number"
		,renderCell: (params) => {
		return RStar(params.value);
	},},
	{field:"roadRateMin", headerName:"Reprise de route", width: 150, type:"number"
		,renderCell: (params) => {
		return RStar(params.value);
	},},
	{field:"type", headerName:"Type", width: 150, type:"string"},
	{field:"fmass", headerName:"Masse", width: 100, type:"number",
		renderCell: (params) => {
			return `${prettyNumber(params.value)} kg`;
		},},
	{field:"price", headerName:"Prix", width: 100, type:"number"
		,renderCell: (params) => {
			return `$${prettyNumber(params.value)}`;
		},},
]


const StatArray = () => {
	const [xmlData, setXmlData] = useState(null);
	const [txtData, setTxtData] = useState(null);
	const [rows, setRows] = useState([]);

	useEffect(() => {
		fetch("/conglomerate.xml")
			.then(response => response.text())
			.then((xmlText) => {
				const parser = new DOMParser();
				const xml = parser.parseFromString(xmlText, "application/xml");
				//console.log(xml);
				setXmlData(xml);
			})
			.catch((error) => {
				console.error("Error while fetching conglomerate.xml")
				console.error(error);
			})
		fetch("/available.txt")
			.then(response => response.text())
			.then((text) => {
				const data = {};
				text.split("\n").forEach((item) => {
					const buf = item.split("\t");
					data[buf[0].toLowerCase()] = buf.splice(1);
				})
				console.log(data)
				setTxtData(data);
			})
			.catch((error) => {
				console.error("Error while fetching available.txt")
				console.error(error);
			})
	}, [])

	useEffect(() => {
		if (xmlData != null || txtData != null) {
			console.log("refreshing rows")
			const _r = [];
			xmlData.querySelectorAll("Item[type=\"CHandlingData\"]").forEach((item) => {
				const id = item.querySelector("handlingName").innerHTML;
				if (!txtData[id.toLowerCase()]) return;
				const data = txtData[id.toLowerCase()];
				const n = {
					name: data[1],
					price: data[2],
					type: data[0],
					id: item.querySelector("handlingName").innerHTML,
					fmass: Math.round(item.querySelector("fMass").getAttribute("value")),
					fInitialDragCoeff: item.querySelector("fInitialDragCoeff").getAttribute("value"),
					fDownForceModifier: item.querySelector("fDownForceModifier")?.getAttribute("value") || undefined,
					fPopUpLightRotation: item.querySelector("fPopUpLightRotation")?.getAttribute("value") || undefined,
					fPercentSubmerged: item.querySelector("fPercentSubmerged").getAttribute("value"),
					vecCentreOfMassOffset: [
						item.querySelector("vecCentreOfMassOffset").getAttribute("x"),
						item.querySelector("vecCentreOfMassOffset").getAttribute("y"),
						item.querySelector("vecCentreOfMassOffset").getAttribute("z")
					],
					vecInertiaMultiplier: [
						item.querySelector("vecInertiaMultiplier").getAttribute("x"),
						item.querySelector("vecInertiaMultiplier").getAttribute("y"),
						item.querySelector("vecInertiaMultiplier").getAttribute("z")
					],
					nInitialDriveGears: item.querySelector("nInitialDriveGears").getAttribute("value"),
					fDriveBiasFront: item.querySelector("fDriveBiasFront").getAttribute("value"),
					fInitialDriveForce: item.querySelector("fInitialDriveForce").getAttribute("value"),
					fDriveInertia: item.querySelector("fDriveInertia").getAttribute("value"),
					fClutchChangeRateScaleUpShift: item.querySelector("fClutchChangeRateScaleUpShift").getAttribute("value"),
					fClutchChangeRateScaleDownShift: item.querySelector("fClutchChangeRateScaleDownShift").getAttribute("value"),
					fInitialDriveMaxFlatVel: item.querySelector("fInitialDriveMaxFlatVel").getAttribute("value"),
					fBrakeForce: item.querySelector("fBrakeForce").getAttribute("value"),
					fBrakeBiasFront: item.querySelector("fBrakeBiasFront").getAttribute("value"),
					fHandBrakeForce: item.querySelector("fHandBrakeForce").getAttribute("value"),
					fSteeringLock: item.querySelector("fSteeringLock").getAttribute("value"),
					fTractionCurveMax: item.querySelector("fTractionCurveMax").getAttribute("value"),
					fTractionCurveMin: item.querySelector("fTractionCurveMin").getAttribute("value"),
					fTractionCurveLateral: item.querySelector("fTractionCurveLateral").getAttribute("value"),
					fTractionSpringDeltaMax: item.querySelector("fTractionSpringDeltaMax").getAttribute("value"),
					fLowSpeedTractionLossMult: item.querySelector("fLowSpeedTractionLossMult").getAttribute("value"),
					fCamberStiffnesss: item.querySelector("fCamberStiffnesss").getAttribute("value"),
					fTractionBiasFront: item.querySelector("fTractionBiasFront").getAttribute("value"),
					fTractionLossMult: item.querySelector("fTractionLossMult").getAttribute("value"),
					fSuspensionForce: item.querySelector("fSuspensionForce").getAttribute("value"),
					fSuspensionCompDamp: item.querySelector("fSuspensionCompDamp").getAttribute("value"),
					fSuspensionReboundDamp: item.querySelector("fSuspensionReboundDamp").getAttribute("value"),
					fSuspensionUpperLimit: item.querySelector("fSuspensionUpperLimit").getAttribute("value"),
					fSuspensionLowerLimit: item.querySelector("fSuspensionLowerLimit").getAttribute("value"),
					fSuspensionRaise: item.querySelector("fSuspensionRaise").getAttribute("value"),
					fSuspensionBiasFront: item.querySelector("fSuspensionBiasFront").getAttribute("value"),
					fAntiRollBarForce: item.querySelector("fAntiRollBarForce").getAttribute("value"),
					fAntiRollBarBiasFront: item.querySelector("fAntiRollBarBiasFront").getAttribute("value"),
					fRollCentreHeightFront: item.querySelector("fRollCentreHeightFront").getAttribute("value"),
					fRollCentreHeightRear: item.querySelector("fRollCentreHeightRear").getAttribute("value"),
					fCollisionDamageMult: item.querySelector("fCollisionDamageMult").getAttribute("value"),
					fWeaponDamageMult: item.querySelector("fWeaponDamageMult").getAttribute("value"),
					fDeformationDamageMult: item.querySelector("fDeformationDamageMult").getAttribute("value"),
					fEngineDamageMult: item.querySelector("fEngineDamageMult").getAttribute("value"),
					fPetrolTankVolume: item.querySelector("fPetrolTankVolume").getAttribute("value"),
					fOilVolume: item.querySelector("fOilVolume").getAttribute("value"),
					fSeatOffsetDistX: item.querySelector("fSeatOffsetDistX")?.getAttribute("value") || undefined,
					fSeatOffsetDistY: item.querySelector("fSeatOffsetDistY")?.getAttribute("value") || undefined,
					fSeatOffsetDistZ: item.querySelector("fSeatOffsetDistZ")?.getAttribute("value") || undefined,
					nMonetaryValue: item.querySelector("nMonetaryValue").getAttribute("value"),
					strModelFlags: item.querySelector("strModelFlags").innerHTML,
					strHandlingFlags: item.querySelector("strHandlingFlags").innerHTML,
					strDamageFlags: item.querySelector("strDamageFlags").innerHTML,
				}

				// speed rate. calculated from the raw speed value
				// the rate will be 1 if lower than 50 and 10 if higher than 200. and interpolated between.
				const lowSpeed = 100;
				const highSpeed = 170;
				n.speedScore = n.fInitialDriveMaxFlatVel;
				n.speedRate = range(lowSpeed, highSpeed, 0, 5, n.speedScore);

				
				// braking rate.
				const lowBrake = 0.5;
				const highBrake = 1.5;
				n.brakeScore = n.fBrakeForce;
				n.brakeRate = range(lowBrake, highBrake, 0, 5, n.brakeScore);


				// Road holding rate.
				const lowRoad = 1;
				const highRoad = 3;
				n.roadScore = n.fTractionCurveMax;
				n.roadRateMax = range(lowRoad, highRoad, 0, 5, n.roadScore);
				n.roadScore2 = n.fTractionCurveMin;
				n.roadRateMin = range(lowRoad, highRoad, 0, 5, n.roadScore2);






				_r.push(n);
			})
			setRows(_r);
		}
	}, [xmlData, txtData])

	return (
		<Box sx={{
			display:"flex",
			alignItems:"center",
			justifyContent:"center",
			align:"center",
			maxWidth:"80%",
			margin:"5% auto 5% auto"
		}}>
			<DataGrid
				rows = {rows}
				columns = {collums}
				initialState={{
					pagination: {
						paginationModel: {
							pageSize: 10,
						},
					},
				}}
				disableRowSelectionOnClick
			/>

		</Box>
	)

}

export default StatArray;